


















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectField from './SelectField.vue'
import {
  assignationStates,
  assignationTypes,
  availableForOptions,
  categories,
  floors,
  types,
  levels
} from '@/utils/property'
import { getRemoteClients } from '@/api/helpers'
import {
  getTranslations,
  hasRoles,
  showAttribute
} from '@/utils'
import { FilterOperator, FilterType } from '@/utils/filter'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'BasicInformation',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  private clientsLoading = false
  private clients: any = []
  private floors = floors
  private levels = levels
  private availableForOptions = availableForOptions
  private getTranslations = getTranslations
  private showAttribute = showAttribute

  private basicElements = {
    available_for: {
      model: 'available_for',
      labelKey: 'availableFor',
      optionsKey: 'available_for',
      isDisabled: true,
      items: availableForOptions
    },
    category: {
      model: 'category',
      labelKey: 'category',
      optionsKey: 'property_category',
      isDisabled: true,
      items: categories.map((item) => item.key)
    },
    subcategory: {
      model: 'type',
      labelKey: 'subcategory',
      optionsKey: 'property_type',
      items: types.filter((item) => {
        return item.showOn === this.property.category
      }).map((item) => {
        return item.key
      })
    }
  }

  private assignationFields = {
    assignation_type: {
      model: 'assignation_type',
      labelKey: 'assignationType',
      optionsKey: 'assignation_type',
      items: assignationTypes
    },
    assignation_state: {
      model: 'assignation_state',
      labelKey: 'assignationState',
      optionsKey: 'assignation_state',
      items: this.property.auction ? ['active', 'inactive'] : assignationStates
    }
  }

  private onAssignationStartChange() {
    const curDate = new Date(this.property.assignation_start)
    curDate.setFullYear(curDate.getFullYear() + 1)
    this.property.assignation_finish = curDate.getTime()
  }

  created() {
    if (this.property.client) {
      this.clients.push(this.property.client)
    }
  }

  get fullCode() {
    const arr = this.property.full_code.split('-')
    arr.pop()
    return arr.join('-') + '-' + this.property.code
  }

  private async getRemoteClientsList(query: string) {
    const items = []
    if (hasRoles(['broker'], UserModule.roles)) {
      items.push({
        type: FilterType.field,
        key: 'user_id',
        value: UserModule.id,
        operator: FilterOperator.eq
      })
    }
    this.clientsLoading = true
    this.clients = await getRemoteClients(query, items)
    this.clientsLoading = false
  }
}
