























































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import SelectField from '@/views/property/components/SelectField.vue'
import {gardenTypes, orientations, roadTypes, seaDistanceOptions, zoningOptions} from '@/utils/property'
import {showAttribute} from '@/utils'

@Component({
  name: 'PropertyPosition',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private showAttribute = showAttribute

  private selectFields = {
    sea_distance: {
      model: 'sea_distance',
      labelKey: 'seaDistance',
      optionsKey: 'sea_distance',
      items: seaDistanceOptions
    },
    orientation: {
      model: 'orientation',
      labelKey: 'orientation',
      optionsKey: 'orientation',
      items: orientations
    },
    zoning: {
      model: 'zoning',
      labelKey: 'zoning',
      optionsKey: 'zoning',
      items: zoningOptions
    },
    road_type: {
      model: 'road_type',
      labelKey: 'road_type',
      optionsKey: 'road_type',
      items: roadTypes
    },
    garden_type: {
      model: 'garden_type',
      labelKey: 'garden_type',
      optionsKey: 'garden_type',
      items: gardenTypes
    }
  }
}
