

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tinymce from '@/components/Tinymce/index.vue'
import { translate } from '@/api/translator'

const LANGUAGE_MAPPING: Record<string, string> = {
  al: 'sq',
  se: 'sv'
}

@Component({
  name: 'Comments',
  components: {
    Tinymce
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any;
  @Prop({ required: true }) private translations!: any;
  @Prop({ required: true }) private language!: string;

  @Watch('language')
  private onLanguageChange(locale: string) {
    this.translations[locale].description = this.translations[locale].description || ''
    this.translations[locale].portal = this.translations[locale].portal || ''
  }

  get sourceDescriptionLang() {
    return this.getSourceLang('description')
  }

  get sourcePortalLang() {
    return this.getSourceLang('portal')
  }

  get sourceDescription() {
    return this.translations[this.sourceDescriptionLang].description
  }

  get sourcePortal() {
    return this.translations[this.sourcePortalLang].portal
  }

  get targetLang() {
    return LANGUAGE_MAPPING[this.language] || this.language
  }

  get googleTranslateURL() {
    const baseURL = 'https://translate.google.com/'
    const queryParams = new URLSearchParams({
      sl: this.sourceDescriptionLang,
      tl: this.targetLang,
      text: this.stripHtmlTags(this.sourceDescription)
    })
    return `${baseURL}?${queryParams.toString()}`
  }

  private getSourceLang(field: string): string {
    const sourceLang = this.isValidField('en', field) ? 'en' : 'el'
    return this.targetLang === sourceLang ? (sourceLang === 'en' ? 'el' : 'en') : sourceLang
  }

  private translateDescription() {
    this.handleTranslation(this.sourceDescriptionLang, this.sourceDescription, 'description')
  }

  private translatePortal() {
    this.handleTranslation(this.sourcePortalLang, this.sourcePortal, 'portal')
  }

  private redirectToGoogleTranslateForDescription() {
    const url = this.googleTranslateURLForField('description', this.sourceDescriptionLang, this.sourceDescription)
    window.open(url, '_blank')
  }

  private redirectToGoogleTranslateForPortal() {
    const url = this.googleTranslateURLForField('portal', this.sourcePortalLang, this.sourcePortal)
    window.open(url, '_blank')
  }

  openGoogleTranslateForField(field: string) {
    const sourceLang = this.getSourceLang(field)
    const text = field === 'description' ? this.sourceDescription : this.sourcePortal
    const url = this.googleTranslateURLForField(field, sourceLang, text)
    window.open(url, '_blank')
  }

  private googleTranslateURLForField(field: string, sourceLang: string, text: string) {
    const baseURL = 'https://translate.google.com/'
    const queryParams = new URLSearchParams({
      sl: sourceLang,
      tl: this.targetLang,
      text: this.stripHtmlTags(text)
    })
    return `${baseURL}?${queryParams.toString()}`
  }

  private async handleTranslation(source: string, text: string, field: string) {
    try {
      const { data } = await translate({ source, target: this.targetLang, text })
      this.translations[this.language][field] = data
    } catch (error) {
      console.error(this.$t('propertyDetail.translationFailed').toString())
    }
  }

  openGoogleTranslate() {
    window.open(this.googleTranslateURL, '_blank')
  }

  isValidField(language: string, field: string): boolean {
    return !!this.translations?.[language]?.[field]?.trim()
  }

  stripHtmlTags(input = ''): string {
    return new DOMParser().parseFromString(input, 'text/html').body.textContent || ''
  }
}
