





































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  energyClasses,
  heatingOptions
} from '@/utils/property'
import { showAttribute } from '@/utils'
import SelectField from '@/views/property/components/SelectField.vue'

@Component({
  name: 'Heating',
  components: {
    SelectField
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any

  private showAttribute = showAttribute

  private energyFields = {
    heating: {
      model: 'heating',
      labelKey: 'heating',
      optionsKey: 'heating',
      items: heatingOptions
    },
    energy_class: {
      model: 'energy_class',
      labelKey: 'energyClass',
      optionsKey: 'energy_class',
      items: energyClasses
    }
  }
}
